<template>
  <div>
    <CModal
      :show.sync="edit_img"
      :close-on-backdrop="false"
      :centered="true"
      :title="$t('cloud.title.edit_image')"
      color="primary"
    >
      <div>
        <el-form @submit.native.prevent>
          <input type="file" ref="fileInput" accept="image/jpeg,image/png" @change="handleImage" hidden />
          <el-form-item v-if="show_crop_ui">
              <div class="mb-1">
                <Jcrop
                  :src="image_uri"
                  @update="onCrop"
                  :rect="rect"
                  :options="options">
                </Jcrop>
              </div>
              <canvas ref="cropCanvas" class="border"></canvas>
          </el-form-item>
          <el-form-item v-else>
            <CCard>
              <img v-if="user_profile.image_signed_url" :src="img_src">
              <el-button type="primary" @click="onUpload">{{ $t('cloud.title.select_image') }}</el-button>
            </CCard>
          </el-form-item>
        </el-form>
      </div>

      <template #footer>
        <CButton v-if="!show_crop_ui" @click="onCancelImg" color="secondary">{{ $t('button.cancel') }}</CButton>
        <CButton v-if="!show_crop_ui" @click="onSubmitImg" color="primary">{{ $t('button.apply') }}</CButton>
        <CButton v-if="show_crop_ui" @click="onBackImg" color="secondary">{{ $t('button.prev') }}</CButton>
        <CButton v-if="show_crop_ui" @click="onNextImg" color="primary">{{ $t('button.next') }}</CButton>
      </template>
    </CModal>

    <CRow class="justify-content-center">
      <CCol md="12" lg="8">
        <CRow class="justify-content-start m-4">
          <el-button class="ml-2" type="text" icon="el-icon-arrow-left" @click="$router.go(-1)">{{ $t('cloud.title.go_back') }}</el-button>
        </CRow>

        <CRow class="justify-content-center">
          <CCard class="w-75">
          <CCardHeader>
            <div class="mx-1 custom-setting-title-label">{{ $t('cloud.title.profile') }}</div>
            <div>
              <CButton v-if="!open_edit_1" class="mx-1 custom-setting-config-btn" @click="open_edit_1=true" color="info">{{ $t('button.edit') }}</CButton>
              <CButton v-if="open_edit_1" class="mx-1 custom-setting-config-btn" @click="onSubmit('default')" color="info">{{ $t('button.apply') }}</CButton>
              <CButton v-if="open_edit_1" class="mx-1 custom-setting-config-btn" @click="onCancel('default')" color="secondary">{{ $t('button.cancel') }}</CButton>
            </div>
          </CCardHeader>
          <CCardBody>
            <CRow class="mx-2 mb-3">
              <CCol class="custom-setting-label" sm="3" >
                E-Mail
              </CCol>
              <CCol sm="9">
                <CInput disabled v-model="user_profile.email" />
              </CCol>
            </CRow>
            <CRow class="mx-2 mb-3">
              <CCol class="custom-setting-label" sm="3" >
                {{ $t('cloud.title.name') }}
              </CCol>
              <CCol sm="9">
                <CInput :disabled="!open_edit_1" v-model="user_profile.name" />
              </CCol>
            </CRow>
            <CRow class="mx-2 mb-3">
              <CCol class="custom-setting-label" sm="3">
                {{ $t('cloud.title.phone_number') }}
              </CCol>
              <CCol sm="9">
                <CInput :disabled="!open_edit_1" v-model="user_profile.cellphone" />
              </CCol>
            </CRow>
            <CRow class="mx-2 mb-3">
              <CCol class="custom-setting-label" sm="3">
                {{ $t('cloud.title.picture') }}
              </CCol>
              <CCol sm="9">
                <img class="custom-userprofile-image-size" :src="img_src" />
                <CButton v-if="open_edit_1" class="mx-1 custom-setting-img-btn" @click="edit_img=true" color="info">{{ $t('button.change') }}</CButton>
                <CButton v-if="open_edit_1" class="mx-1 custom-setting-img-btn" @click="onDefaultImg" color="secondary">{{ $t('button.delete') }}</CButton>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        </CRow>

        <CRow class="justify-content-center">
          <CCard class="w-75">
            <CCardHeader>
              <div class="mx-1 custom-setting-title-label">{{ $t('cloud.title.security_info') }}</div>
              <div>
                <CButton v-if="!open_edit_3" class="mx-1 custom-setting-config-btn" @click="onOpenEdit('security')" color="info">{{ $t('button.pw_change') }}</CButton>
                <CButton v-if="open_edit_3" class="mx-1 custom-setting-config-btn" @click="onSubmit('security')" color="info">{{ $t('button.apply') }}</CButton>
                <CButton v-if="open_edit_3" class="mx-1 custom-setting-config-btn" @click="onCancel('security')" color="secondary">{{ $t('button.cancel') }}</CButton>
              </div>
            </CCardHeader>

            <CCardBody>
              <CRow class="mx-2 mb-3">
                <CCol sm="3" >
                  {{ $t('cloud.title.account_created') }}
                </CCol>
                <CCol sm="9">
                  <!-- <CInput :disabled="true" v-model="user_profile.date_joined" /> -->
                  <!-- {{user_profile.date_joined}} -->
                  {{date_joined}}
                </CCol>
              </CRow>
              <CRow class="mx-2 mb-3">
                <CCol sm="3">
                  {{ $t('cloud.title.last_login') }}
                </CCol>
                <CCol sm="9">
                  {{lastLogin}}
                </CCol>
              </CRow>

              <CRow v-if="open_edit_3" class="mx-2 mb-3">
                <CCol class="custom-setting-label" sm="3">
                  {{ $t('cloud.title.new_password') }}
                </CCol>
                <CCol sm="9">
                  <CInput type="password" v-model="password" />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import axios from 'axios';
import { Jcrop } from 'vue-jcrop';
import utility from '@/utility.js';

export default {
  name: 'UserProfile',
  components : {
    Jcrop,
  },
  computed: {
    ...mapState([
      'capability'
    ]),
    ...mapActions([
      'refreshCapability'
    ]),
    lastLogin : function(){
      let last_login = new Date(this.user_profile.last_login);
      return last_login.toLocaleString(this.capability.user_profile.locale);
    },
    date_joined : function(){
      let date_joined = new Date(this.user_profile.date_joined);
      return date_joined.toLocaleString(this.capability.user_profile.locale);
    },
    img_src : function(){
      if(!this.user_profile.image_signed_url)
        return "/img/default_user.png"
      else
        return this.user_profile.image_signed_url;
    }
  },
  data(){
    return {
      user_profile : null,
      before_user_profile : null,

      password : null,

      open_edit_1 : false,
      open_edit_3 : false,

      //img
      edit_img:false,
      image_uri:null, // image test
      show_crop_ui:false,
      rect: [0, 0, 80, 80],
      position: {},
      options: {
        handles: ['se'],
        aspectRatio: 80 / 80
      },
      image_crop_uri: null,
      image_blob: null,
    }
  },
  created(){
    this.user_profile = JSON.parse(JSON.stringify(this.capability.user_profile));
    this.before_user_profile = JSON.parse(JSON.stringify(this.user_profile)); // 백업
  },
  methods : {
    onOpenEdit(type){
      if(type==="default"){
        this.open_edit_1 = true;
      }
      if(type==="security"){
        this.open_edit_3 = true;
      }
    },
    onSubmit(type){
      const fd = new FormData;
      fd.append("auth_level", this.user_profile.auth_level)

      if(!this.validate()) 
        return;

        if(type==="default"){
          fd.append("name", this.user_profile.name);
          let blob = null;
          if (this.image_crop_uri === 'clear') {
            fd.append("image", null);
          } else if (this.image_crop_uri) {
            blob = utility.DataURI2Blob(this.image_crop_uri);
            fd.append("image", blob, "user_image.jpg");
          }
          fd.append("email", this.user_profile.email);
          fd.append("cellphone", this.user_profile.cellphone);
        }
        if(type==="security"){
          fd.append("password", this.password)
        }

        axios.put(`/api/users/${this.user_profile.id}/`, fd)
        .then(result => {
          this.user_profile = result.data;
          this.before_user_profile = JSON.parse(JSON.stringify(this.user_profile));
          if(type==="default"){
            this.open_edit_1 = false;
            this.$notify.success({
              title: this.$t('cloud.title.success'),
              message: this.$t('cloud.title.request_done'),
              offset: 30
            });
            this.$store.dispatch('refreshCapability');
          }
          if(type==="security"){
            this.password = null;
            this.open_edit_3 = false;
            this.$notify.success({
              title: this.$t('cloud.title.success'),
              message: this.$t('cloud.title.inform_password_change'),
              offset: 30
            });
            this.$api.Logout((response) => {
              this.$store.commit('set', ['capability', null]);
              this.$router.push('/pages/login');
            }, (error) => {
              console.error(error);
            })
          }
        })
        .catch(error => {
          console.error(error);
        })
    },
    onCancel(type){
      if(type==="default"){
        this.user_profile.image_signed_url = this.before_user_profile.image_signed_url;
        this.user_profile.first_name = this.before_user_profile.first_name;
        this.user_profile.last_name = this.before_user_profile.last_name;
        this.open_edit_1 = false;
        this.user_profile.cellphone = this.before_user_profile.cellphone;
        this.user_profile.email = this.before_user_profile.email;
      }
      if(type==="security"){
        this.password = null;
        this.open_edit_3 = false;
      }
    },
    onDefaultImg(){
      this.user_profile.image_signed_url = "";
      this.image_crop_uri = 'clear';
    },

    // returnDefaultImgBlob(){
    //   const reader = new FileReader;
    //   const img = new Image;

    //   reader.onload = event => {
    //     img.onload = _=>{
    //       if(img.width <40 || img.height < 40){
    //         this.notification = 'Image is too small. Minimum size is 40x40.';
    //         this.dismissCountDown = 3;
    //         return;
    //       }
    //       canvas.width = img.width;
    //       canvas.height = img.height;
    //       ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    //       this.image_uri = utility.ContainImageSize(img, 540, 400);
    //     }
    //     img.src = "/img/default_user.png"; 
    //   }
    //   reader.readAsDataURL("/img/default_user.png");
    // },

    //img
    handleImage(e){
      if(e.target.files.length===0){
        return;
      }
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const reader = new FileReader;
      const img = new Image;

      // image_name
      reader.onload = event => {
        img.onload = _=>{
          if(img.width <40 || img.height < 40){
            this.notification = 'Image is too small. Minimum size is 40x40.';
            this.dismissCountDown = 3;
            return;
          }
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          this.image_uri = utility.ContainImageSize(img, 540, 400);
          this.show_crop_ui = true;
        }
        img.src = event.target.result;
      }
      reader.readAsDataURL(e.target.files[0]);
    },
    onUpload(){
      this.edit_img = true;
      this.$refs.fileInput.click();
    },
    onCrop(e){
      this.position = e.sel.round();
      const img = new Image;
      img.onload = _=>{
        const position = this.position;
        this.cropImage(img, position.x, position.y, position.w, position.h);
      }
      img.src = this.image_uri;
    },
    cropImage(imageObj, x,y,w,h){
      utility.EraseCanvas(this.$refs.cropCanvas);
      const canvas = this.$refs.cropCanvas;
      canvas.width = w;
      canvas.height = h;
      const ctx = canvas.getContext('2d');
      ctx.save();
      ctx.drawImage(imageObj, x,y,w,h, 0,0,w,h);
      ctx.restore();
      this.image_crop_uri = canvas.toDataURL('image/jpeg', 1.0);
    },
    onNextImg(){
      this.user_profile.image_signed_url = this.image_crop_uri;
      this.show_crop_ui = false;
    },
    onBackImg(){
      this.show_crop_ui = false;
    },
    onCancelImg(){
      this.user_profile.image_signed_url = this.before_user_profile.image_signed_url;
      this.edit_img = false;
    },
    onSubmitImg(){
      this.edit_img = false;
    },

    validate(){
      if(!this.user_profile.name){
        this.$notify.warning({
          title: this.$t('cloud.title.alert'),
          message: this.$t('cloud.title.validate.name'),
          offset: 30
        });
        return false;
      }
      if(!this.user_profile.email){
        this.$notify.warning({
          title: this.$t('cloud.title.alert'),
          message: this.$t('cloud.title.no_email'),
          offset: 30
        });
        return false;
      }
      let re = /\S+@\S+\.\S+/;
      if (!re.test(this.user_profile.email)){
        this.$notify.warning({
          title: this.$t('cloud.title.alert'),
          message: this.$t('cloud.title.wrong_email'),
          offset: 30
        });
        return false;
      }

      if(this.open_edit_3){
        if(!this.password){
          this.$notify.warning({
            title: this.$t('cloud.title.alert'),
            message: this.$t('cloud.title.no_password'),
            offset: 30
          });
          return false;
        }
        if(this.password.length<6){
          this.$notify.warning({
            title: this.$t('cloud.title.alert'),
            message: this.$t('cloud.title.validate.password'),
            offset: 30
          });
          return false;
        }
      }
      return true;
    }
  },
}
</script>